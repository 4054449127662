/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@nai-libs/app-config';
import {
  AppConfig,
  Locale,
  SelectedUser,
  ServiceReceiver,
  User,
} from '@nai-libs/data-access';
import { StorageService } from '@nai-libs/shared/utility/src';
import jwtDecode from 'jwt-decode';
import { Observable, catchError, map, of, switchMap, throwError } from 'rxjs';

export const SERVICE_RECEIVER_KEY = 'serviceReceiver';
export const TOKEN_KEY = 'auth_token';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    @Inject(APP_CONFIG) private env: AppConfig,
    private http: HttpClient,
    private storage: StorageService
  ) {}

  getToken(): Observable<string | null> {
    return this.storage.getItem(TOKEN_KEY);
  }

  _getToken(): string | undefined {
    return this.getCookie('auth_token');
  }

  login(email: string, password: string): Observable<string> {
    return this.http
      .post(
        `${this.env.baseUrl}/login-nf`,
        { email, password },
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      )
      .pipe(
        map((res: any) => {
          return res.token.value;
        }),
        catchError((err) => throwError(() => err))
      );
  }

  logout() {
    this.deleteCookie('auth_token');
    this.storage.removeItem(SERVICE_RECEIVER_KEY);
    this.storage.removeItem(TOKEN_KEY);
  }

  fetchUserData(): Observable<User> {
    return this.storage.getItem<string>(TOKEN_KEY).pipe(
      switchMap((token: string | null) => {
        if (token) {
          let decodedToken;
          try {
            decodedToken = jwtDecode(token) as { email: string };
          } catch (error) {
            // IMPROVE Throw error
            return of({ 'valid-token?': false } as User);
          }

          const paramsObj = {
            'familiar-email': decodedToken?.email || '',
          };

          const params = new URLSearchParams(paramsObj).toString();
          return this.http
            .get<User>(
              `${this.env.apiUrl}familiar-profile/fetch-logued-user-data?${params}`
            )
            .pipe(catchError((err) => throwError(() => err)));
        } else {
          return throwError(() => 'Auth error: invalid token');
        }
      })
    );
  }

  deleteCookie(name: string) {
    document.cookie =
      name + '=; Domain=.' + this.env.domain + '; Path=/; max-age=0';
  }

  getCookie(cName: string): string | undefined {
    const name = cName + '=';
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded.split('; ');
    let res;
    cArr.forEach((val) => {
      if (val.indexOf(name) === 0) res = val.substring(name.length);
    });
    return res;
  }

  sendPasswordRecoveryEmail(email: string): Observable<string> {
    const paramsObj = {
      'familiar-email': email,
    };

    return this.http
      .post(
        `${this.env.baseUrl}/familiar-profile/forgotten-password`,
        paramsObj,
        {
          headers: { 'Content-Type': 'application/json' },
          responseType: 'text',
        }
      )
      .pipe(catchError((err) => throwError(() => err)));
  }

  saveNewPassword(
    email: string,
    password: string,
    tempPassword: string
  ): Observable<string> {
    const paramsObj = {
      email: email,
      password: password,
      'temp-password': tempPassword,
    };

    return this.http
      .post(`${this.env.baseUrl}/familiar-profile/save-password`, paramsObj, {
        headers: { 'Content-Type': 'application/json' },
        responseType: 'text',
      })
      .pipe(catchError((err) => throwError(() => err)));
  }

  changePassword(
    oldPassword: string,
    newPassword: string,
    user: User,
    serviceReceiver: ServiceReceiver
  ) {
    const params = {
      'client-phone': serviceReceiver['client-phone'],
      'familiar-email': user['logged-user']['e-mail'],
      'old-pass': oldPassword,
      'new-pass': newPassword,
    };

    return this.http
      .post(`${this.env.apiUrl}familiar-profile/change-password`, params)
      .pipe(catchError((err) => throwError(() => err)));
  }

  acceptUsingTerms(user: User): Observable<string> {
    const paramsObj = {
      'familiar-email': user['logged-user']['e-mail'],
    };

    return this.http
      .post(
        `${this.env.apiUrl}familiar-profile/accept-using-terms`,
        paramsObj,
        {
          headers: { 'Content-Type': 'application/json' },
          responseType: 'text',
        }
      )
      .pipe(catchError((err) => throwError(() => err)));
  }

  persistServiceReceiver(serviceReceiver: ServiceReceiver) {
    this.storage.setItem(SERVICE_RECEIVER_KEY, serviceReceiver['client-phone']);
  }

  getSavedServiceReceiver(): Observable<string | null> {
    return this.storage.getItem(SERVICE_RECEIVER_KEY);
  }

  _getSavedServiceReceiver(): string | null {
    return localStorage.getItem(SERVICE_RECEIVER_KEY);
  }

  changeLanguage(
    lang: Locale,
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser
  ): Observable<string> {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'familiar-email': user['logged-user']['e-mail'],
      language: lang,
      'user-id': selectedUser['user-id'],
    };
    return this.http
      .post(`${this.env.apiUrl}familiar-profile/save-app-language`, paramsObj, {
        headers: { 'Content-Type': 'application/json' },
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          return throwError(() => err);
        })
      );
  }

  changeAvatarColor(
    user: User,
    serviceReceiver: ServiceReceiver,
    selectedUser: SelectedUser,
    color: string,
    mode: 'client' | 'familiar'
  ) {
    const paramsObj = {
      'client-phone': serviceReceiver['client-phone'],
      'user-id': selectedUser['user-id'],
      'familiar-email': user['logged-user']['e-mail'],
      avatar: color,
      role: mode,
    };
    return this.http.post(
      `${this.env.apiUrl}familiar-profile/save-avatar`,
      paramsObj,
      {
        headers: { 'Content-Type': 'application/json' },
        responseType: 'text',
      }
    );
  }
}
