// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { AppConfig } from "@nai-libs/shared/data-access/src";

export const environment: AppConfig = {
  appName: 'macrosad',
  production: false,
  domain: 'ubikare.io',
  baseUrl: 'http://naiha.macrosad.test.ubikare.io:3000',
  apiUrl: 'http://naiha.macrosad.test.ubikare.io:3000/api-nf/',
  availableLanguages: ['es'],
  features: {
    passwordRecovery: true,
    changePassword: true,
    changeLanguage: false,
    faq: false,
    videoconsultations: false
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
